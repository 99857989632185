export default [
  {
    path: '/data/export',
    name: 'app-data-export',
    component: () => import('@/views/pages/export/Export.vue'),
    meta: {
      pageTitle: 'Rapportage',
      breadcrumb: [
        {
          text: 'Data-uitwisseling',
        },
        {
          text: 'export',
          active: true,
        },
      ],
      action: 'read',
      resource: 'data-exchange',
    },
  },
  {
    path: '/data/import',
    name: 'app-data-import',
    component: () => import('@/views/pages/import/Import.vue'),
    meta: {
      pageTitle: 'Importeer facturen',
      breadcrumb: [
        {
          text: 'Data-uitwisseling',
        },
        {
          text: 'import',
          active: true,
        },
      ],
      action: 'read',
      resource: 'data-exchange',
    },
  },
]
