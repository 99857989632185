export default [
  {
    path: '/payroll/slips',
    name: 'app-payslips',
    component: () => import('@/views/pages/pay-slips/PaySlips.vue'),
    meta: {
      pageTitle: 'Loonstroken & Uitbetalingen',
      breadcrumb: [
        {
          text: 'verlonen',
        },
        {
          text: 'loonstroken & uitbetalingen',
        },
        {
          text: 'overzicht',
          active: true,
        },
      ],
      action: 'read',
      resource: 'payslips',
    },
  },
  {
    path: '/payroll/options',
    name: 'app-payout-options',
    component: () => import('@/views/pages/pay-options/PayOptions.vue'),
    meta: {
      pageTitle: 'Verloonopties',
      breadcrumb: [
        {
          text: 'verlonen',
        },
        {
          text: 'opties',
          active: true,
        },
      ],
      action: 'read',
      resource: 'payslips-options',
    },
  },
]
