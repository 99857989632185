export default [
  {
    path: '/administration/invoices',
    name: 'app-invoices',
    component: () => import('@/views/pages/invoices/overview/Invoices.vue'),
    meta: {
      pageTitle: 'Facturen',
      breadcrumb: [
        {
          text: 'administratie',
        },
        {
          text: 'facturen',
          active: true,
        },
      ],
      action: 'read',
      resource: 'invoice',
    },
  },
  {
    path: '/administration/invoices/add',
    name: 'app-invoices-add',
    component: () => import('@/views/pages/invoices/add/InvoicesAdd.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Facturen',
      breadcrumb: [
        {
          text: 'administratie',
        },
        {
          text: 'facturen',
        },
        {
          text: 'toevoegen',
          active: true,
        },
      ],
      action: 'update',
      resource: 'invoice',
    },
  },
  {
    path: '/administration/invoice-collection',
    name: 'app-invoice-collection',
    component: () => import('@/views/pages/invoiceCollection/InvoiceCollection.vue'),
    meta: {
      pageTitle: 'Verzamelfacturen',
      breadcrumb: [
        {
          text: 'administratie',
        },
        {
          text: 'verzamelfacturen',
          active: true,
        },
      ],
      action: 'read',
      resource: 'invoice-collection',
    },
  },
  {
    path: '/administration/companies',
    name: 'app-companies',
    component: () => import('@/views/pages/companies/list/Companies.vue'),
    meta: {
      pageTitle: 'Opdrachtgevers',
      breadcrumb: [
        {
          text: 'administratie',
        },
        {
          text: 'opdrachtgevers',
          active: true,
        },
      ],
      action: 'read',
      resource: 'company',
    },
  },
  {
    path: '/administration/companies/:id?',
    name: 'app-company-edit',
    component: () => import('@/views/pages/companies/edit/CompanyEdit.vue'),
    meta: {
      pageTitle: 'Opdrachtgever',
      breadcrumb: [
        {
          text: 'administratie',
        },
        {
          text: 'opdrachtgever',
        },
        {
          text: 'dossier',
          active: true,
        },
      ],
      action: 'read',
      resource: 'company',
    },
  },
  {
    path: '/administration/freelancers',
    name: 'app-freelancer',
    component: () => import('@/views/pages/freelancers/list/Freelancers.vue'),
    meta: {
      pageTitle: 'Freelancers',
      breadcrumb: [
        {
          text: 'administratie',
        },
        {
          text: 'freelancers',
          active: true,
        },
      ],
      action: 'read',
      resource: 'freelancer',
    },
  },
  {
    path: '/administration/freelancers/:id?',
    name: 'app-freelancer-edit',
    component: () => import('@/views/pages/freelancers/edit/FreelancerEdit.vue'),
    meta: {
      pageTitle: 'Freelancer',
      breadcrumb: [
        {
          text: 'administratie',
        },
        {
          text: 'freelancer',
        },
        {
          text: 'dossier',
          active: true,
        },
      ],
      action: 'read',
      resource: 'freelancer',
    },
  },
]
