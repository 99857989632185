export default [
  {
    path: '/users/list',
    name: 'app-users-list',
    component: () => import('@/views/pages/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Gebruikers',
      breadcrumb: [
        {
          text: 'Gebruikers',
        },
        {
          text: 'overzicht',
          active: true,
        },
      ],
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'app-users-edit',
    component: () => import('@/views/pages/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Gebruikers',
      breadcrumb: [
        {
          text: 'Gebruiker',
        },
        {
          text: 'aanpassen',
          active: true,
        },
      ],
      action: 'read',
      resource: 'users',
    },
  },
]
